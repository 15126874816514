<template>
  <div style="width:99%;margin:auto;">
    <div class="form-title">商品优惠详情</div>
    <div class="approve">
      <div class="basic-info-container">
        <h3 class="basic-info">商品信息</h3>
        <div class="basic-info-content">
          <div style="display:flex;flex-wrap:wrap">
            <div class="grid-content1">名称：<span class="col-text">{{selectGoodsInfo.name}}</span></div>
            <div class="grid-content1">单价：<span class="col-text">{{selectGoodsInfo.unitPrice}}</span></div>
            <div class="grid-content1">分类：<span class="col-text">{{selectGoodsInfo.goodsTypeName}}</span></div>
            <div class="grid-content1">品牌：<span class="col-text">{{selectGoodsInfo.brandName}}</span></div>
            <div class="grid-content1">销售单位：<span class="col-text">{{selectGoodsInfo.saleMeasureUnitName}}{{selectGoodsInfo.saleMeasureUnitType==1?'（单计量）':'（多剂量）'}}</span></div>
            <div class="grid-content1">库存单位：<span class="col-text">{{selectGoodsInfo.stockMeasureUnitName}}</span></div>
            <div class="grid-content1">报表单位：<span class="col-text">{{selectGoodsInfo.reportMeasureUnitName}}</span></div>
            <div class="grid-content1">规格型号：<span class="col-text">{{selectGoodsInfo.model}}</span></div>
           
            <div class="grid-content1"><div >商品图片：</div><UploadImg class="img-list" v-model="selectGoodsInfo.thumbnailList" :limit="1" :disabled="true"></UploadImg></div>
          </div>
          
        </div>
      </div>
      <div class="basic-info-container">
        <h3 class="basic-info">折扣信息</h3>
        <div class="product-header">
          <el-row :gutter="20" class="rol-content">
            <el-col :span="8">
              <div class="grid-content">折扣名称</div>
            </el-col>
            <el-col :span="8">
              <div class="grid-content">折扣</div>
            </el-col>
            <el-col :span="8">
              <div class="grid-content">折后价（元）</div>
            </el-col>
          </el-row>
        </div>
        <div class="product-content" v-for="(favorableInfo,index) in detailData.favorablePriceList" :key="index">
          <el-row :gutter="20" class="rol-content">
            <el-col :span="8">
              <div class="grid-content">{{favorableInfo.name}}</div>
            </el-col>
            <el-col :span="8">
              <div class="grid-content">{{favorableInfo.discount}}</div>
            </el-col>
            <el-col :span="8">
              <div class="grid-content">{{favorableInfo.price}}</div>
            </el-col>
          </el-row>
          <el-divider class="divder-product"></el-divider>
        </div>
        
      </div>
  

    </div>


    <div class="btn-box">
      <el-button @click="cancel">返回</el-button>
    </div>
  </div>
</template>

<script>
import { getGoodsFavorableInfo } from '@/api/index.js'
import UploadImg from '@/components/UploadImg/index.vue'
export default {
  name: 'CustomerForm',
  components: { UploadImg },
  props: ['value', 'editId', 'editType'],
  data() {
    return {
      activeNames: ['1', '2', '3', '4'],
      detailData:{},
      goodsList:[],
      selectGoodsInfo:{},
    }
  },
  created() {
    this.getGoodsFavorableInfo();
  },
  mounted() {
    
  },
  methods: {
    cancel() {
      this.$emit('input', 0)
      this.$emit('changeShowType', 'list')
    },
    //编辑获取详情
    getGoodsFavorableInfo(){
      let param = {id:this.editId};      
      getGoodsFavorableInfo(param).then(res => {
        if (res.code != 200) return
          this.detailData = res.data;    
          this.selectGoodsInfo={...res.data.goodsInfo}    
      })
    },  

  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/info.scss'

</style>
