<template>
  <div style="width:100%">
    <GoodsFavorableList v-if="editType == 'list'"  @changeShowType="changeShowType"></GoodsFavorableList>
    <GoodsFavorableForm v-if="editType == 'edit' || editType == 'add'" :editId="editId"  :editType="editType"  @changeShowType="changeShowType"></GoodsFavorableForm>
    <GoodsFavorableInfo v-if="editType == 'show'" :editId="editId"  :editType="editType"  @changeShowType="changeShowType"></GoodsFavorableInfo>
  </div>
</template>

<script>
import GoodsFavorableList from './components/GoodsFavorableList'
import GoodsFavorableForm from './components/GoodsFavorableForm'
import GoodsFavorableInfo from './components/GoodsFavorableInfo'

export default {
  name: 'goodsFavorable',
  components: { GoodsFavorableForm,GoodsFavorableList,GoodsFavorableInfo },
  data() {
    return {
      editType:'list',
      editId:'',
    }
    
  },
  created() {
  },
  methods: {
    changeShowType(type,id){
      if(type!='add'){
        this.editId = id;
      }
      this.editType = type;
    }
  }
}
</script>

<style>
</style>