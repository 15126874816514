<template>
  <div>
    <div class="form-title">{{editType | typeName}}商品优惠信息</div>
    <el-form size="small" inline ref="form" :model="form" label-width="auto"  >
    <el-collapse v-model="activeNames">
      

      <el-collapse-item title="商品信息" name="1">

        <div style="text-align:center">
          <el-button type="primary" icon="el-icon-search" v-if="editType!='show'" @click="showSelectGoods(true)">选择商品</el-button>
        </div>
        <div style="width:780px; text-align:left;margin:auto;">
          <div class="goodsBox" v-if="selectGoodsInfo.id" >
            <div class="goods-img" title="点击查看大图">
              <el-image :src="getFirstFileByList(selectGoodsInfo.thumbnailList).url" class="image"  @click.stop="showBigImage(getFirstFileByList(selectGoodsInfo.thumbnailList).url)"></el-image>
             </div>
            <div class="info-box">
              <div class="info-box-top">
                <div class="info-item">名称：{{selectGoodsInfo.name}}</div>
                <div class="info-item">单价：{{selectGoodsInfo.unitPrice}}元</div>
                <div class="info-item">分类：{{selectGoodsInfo.goodsTypeName}}</div>
                <div class="info-item">品牌：{{selectGoodsInfo.brandName}}</div>
              </div>
              <div class="info-box-bottom">
                <div class="info-item">销售单位：{{selectGoodsInfo.saleMeasureUnitName}}{{selectGoodsInfo.saleMeasureUnitType==1?'（单计量）':'（多剂量）'}}</div>
                <div class="info-item">库存单位：{{selectGoodsInfo.stockMeasureUnitName}}</div>
                <div class="info-item">报表单位：{{selectGoodsInfo.reportMeasureUnitName}}</div>
                <div class="info-item">规格型号：{{selectGoodsInfo.model}}</div>
              </div>

            </div>
          </div>
        </div>

        <div style="width:720px;text-align:left;margin:auto;margin-top:30px;">          
          <el-form-item label="备注" prop="code"  >
              <el-input v-model="form.remark" type="textarea" maxlength="200" placeholder="请输入备注" :disabled="disabled" style="width:400px;"></el-input>
          </el-form-item>
        </div>
      </el-collapse-item>

      <el-collapse-item title="优惠信息" name="1">
        <div style="text-align:center;">
          <div style="margin:auto;" v-for="(favorableInfo,index) in form.favorablePriceList" :key="index">
            <el-form-item label="折扣名称">
              <el-input v-model="favorableInfo.name" placeholder="请输入名称" style="width:130px" :disabled="disabled"></el-input>
            </el-form-item>          
            <el-form-item label="折扣"  >
                <el-input v-model="favorableInfo.discount" type="number" placeholder="请输入1-10" :disabled="disabled" style="width:130px"></el-input>
            </el-form-item>
            <el-form-item label="折后价"  >
                <el-input v-model="favorableInfo.price" type="number" placeholder="请输入折后价" :disabled="disabled" style="width:130px"></el-input>
            </el-form-item>
            <el-form-item label="" >
              <el-button type="success" icon="el-icon-plus" circle  @click="addFavorableInfo" v-if="index+1==form.favorablePriceList.length && editType!='show'"></el-button>
                <el-button type="danger" icon="el-icon-delete" :class="{'del-margin':index+1!=form.favorablePriceList.length}" circle @click="delFavorableInfo(index)" v-if="form.favorablePriceList.length>1 && editType!='show'"></el-button>
            </el-form-item>
          </div>

        </div>

        
      </el-collapse-item>
    </el-collapse>
    <div class="btn-box">
      <el-button @click="cancel">返回</el-button>
      <el-button type="primary" v-debounce="submit" v-if="editType!='show'" >保存</el-button>
    </div>

    </el-form>

    <SelectGoods v-if="isShowSelectGoodsModal" v-model="isShowSelectGoodsModal" @okSelectedGoods="okSelectedGoods" ></SelectGoods>

    <el-image-viewer v-if="imgDialogVisible" :on-close="onClose" :url-list="[bigImageUrl]" />


  </div>
</template>

<script>
import { addGoodsFavorableInfo, updateGoodsFavorableInfo,getGoodsFavorableInfo } from '@/api/index.js'
import UploadImg from '@/components/UploadImg/index.vue'
import ChooseUnit from '@/components/ChooseUnit/index.vue'
import ChooseBrand from '@/components/ChooseBrand/index.vue'
import ChooseGoodsType from '@/components/ChooseGoodsType/index.vue'
import SelectGoods from '@/components/SingleChooseGoods/index.vue'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
export default {
  name: 'CustomerForm',
  components: { UploadImg,ChooseUnit,ChooseBrand,ChooseGoodsType,SelectGoods,ElImageViewer },
  props: ['value', 'editId', 'editType'],
  data() {
    return {
      activeNames: ['1', '2'],
      form:{
        id:'',
        goodsId:'',
        goodsName:'',
        remark:'',
        favorablePriceList:[
          {name:'',discount:'',price:''}
        ],
      },
      disabled:false,
      isShowSelectGoodsModal: false,
      imgDialogVisible:false,
      bigImageUrl:'',
      selectGoodsInfo:{},
      tempFavorableInfo:{name:'',discount:'',price:''}
    }
  },
  created() {
    this.disabled = this.editType=='show';
    if (this.editType !='add' ) {
      this.form.id = this.editId;
      this.getGoodsFavorableInfo();
    }
  },
  mounted() {
    
  },
  methods: {
    cancel() {
      this.$emit('input', 0)
      this.$emit('changeShowType', 'list')
    },
    showBigImage(url){
      this.imgDialogVisible = true;
      this.bigImageUrl = url;
    },
    onClose() {
      this.imgDialogVisible = false
    },
    removeGoods(index){
      this.form.goodsList.splice(index,1)
      this.handlePackagePrice();
    },
    okSelectedGoods(goodsInfo){
      this.selectGoodsInfo = goodsInfo;     
     
      this.isShowSelectGoodsModal = false;
      //this.handlePackagePrice();
    },
    showSelectGoods(type){
      this.isShowSelectGoodsModal = type;
    },
    addFavorableInfo(){
      this.form.favorablePriceList.push({...this.tempFavorableInfo})
    },
    delFavorableInfo(index){
      this.form.favorablePriceList.splice(index,1)
    },
    //编辑获取详情
    getGoodsFavorableInfo(){
      let param = {id:this.editId};      
      getGoodsFavorableInfo(param).then(res => {
        if (res.code != 200) return
        this.form = res.data;  
        this.selectGoodsInfo={...res.data.goodsInfo}      
      })
    },
    getFirstFileByList(list){
      if(list && list.length>0){
        return list[0];
      }
      return {};
    },
    submit() {      
     if (!this.$validate('form')) return
     if(!this.selectGoodsInfo.id){
        this.$message.error('请选择商品')
        return;
      }
      this.form.goodsId=this.selectGoodsInfo.id;
      this.form.goodsName = this.selectGoodsInfo.name;
      let form = JSON.parse(JSON.stringify(this.form));
      let success = res => {
        if (res.code != 200) {
          this.$message.error(res.msg || '操作失败')
          return
        }
        this.$message.success('操作成功')
        this.cancel()
      }
      if (this.editType == 'add') {
        addGoodsFavorableInfo(form).then(success)
      } else {
        updateGoodsFavorableInfo(form).then(success)
      }
    },
  },
}
</script>

<style scoped>
.goodsBox{
  width:870px; 
  display: flex; 
  border: 2px solid #C9E3FB;
  border-radius: 5px;
  margin-top:10px;
}
.goodsBox .goods-img{
    flex: 0 0 100px;
    height: 100px;
    width: 100px;
    line-height: 100px;
    cursor: pointer;
}
.goodsBox .goods-img .image{
    height: 80px;
    width: 80px;
    margin: 10px 10px;
}
.goodsBox .info-box{
  flex: 0 0 720px;

}
.goodsBox .info-box .info-box-top{
  height: 50px;
  display: flex;
}
.goodsBox .info-box .info-box-bottom{
  height: 50px;
  display: flex;
}
.goodsBox .info-box .info-item{
  flex: 0 0 180px;
  line-height: 50px;
  text-align: left;
  font-size: 14px;
  color: #606266;
}

.goodsBox .del-box{
  flex: 1;
  width: 50px;
  line-height: 100px;
}
.del-margin{
  margin-left: 40px;
}

</style>
